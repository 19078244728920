<template>
  <div class="intro-y mt-8">
    <h2 class="text-lg font-medium mr-auto">系統配置</h2>
    <form class="validate-form" @submit.prevent="save">
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="col-span-12 lg:col-span-6">
          <!-- BEGIN: Form Layout -->
          <div class="intro-y box p-5 h-full">
            <div>
              <label>後台網站標題</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{
                    'border-theme-6': validate.AdminTitle.$error,
                  }">
                  <FontAwesome icon="quote-right" class="w-6" />
                </div>
                <input v-model.trim="validate.AdminTitle.$model" type="text"
                  class="input pl-12 w-full border col-span-4" :class="{
                    'border-theme-6': validate.AdminTitle.$error,
                  }" placeholder="請輸入標題" name="AdminTitle" />
              </div>
              <template v-if="validate.AdminTitle.$error">
                <div v-for="(error, index) in validate.AdminTitle.$errors" :key="index" class="text-theme-6 mt-2">
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>入口網站標題</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{
                    'border-theme-6': validate.PortalTitle.$error,
                  }">
                  <FontAwesome icon="quote-right" class="w-6" />
                </div>
                <input v-model.trim="validate.PortalTitle.$model" type="text"
                  class="input pl-12 w-full border col-span-4" :class="{
                    'border-theme-6': validate.PortalTitle.$error,
                  }" placeholder="請輸入標題" name="PortalTitle" />
              </div>
              <template v-if="validate.PortalTitle.$error">
                <div v-for="(error, index) in validate.PortalTitle.$errors" :key="index" class="text-theme-6 mt-2">
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>入口網站關鍵字</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{
                    'border-theme-6': validate.PortalMetaKeywords.$error,
                  }">
                  <FontAwesome icon="quote-right" class="w-6" />
                </div>
                <input v-model.trim="validate.PortalMetaKeywords.$model" type="text"
                  class="input pl-12 w-full border col-span-4" :class="{
                    'border-theme-6': validate.PortalMetaKeywords.$error,
                  }" placeholder="請輸入關鍵字，並以逗號隔開" name="PortalMetaKeywords" />
              </div>
              <template v-if="validate.PortalMetaKeywords.$error">
                <div v-for="(error, index) in validate.PortalMetaKeywords
                .$errors" :key="index" class="text-theme-6 mt-2">
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>網站描述</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{
                    'border-theme-6':
                      validate.PortalMetaDescription.$error,
                  }">
                  <FontAwesome icon="quote-right" class="w-6" />
                </div>
                <input v-model.trim="validate.PortalMetaDescription.$model" type="text"
                  class="input pl-12 w-full border col-span-4" :class="{
                    'border-theme-6':
                      validate.PortalMetaDescription.$error,
                  }" placeholder="請輸入描述文字" name="PortalMetaDescription" />
              </div>
              <template v-if="validate.PortalMetaDescription.$error">
                <div v-for="(error, index) in validate.PortalMetaDescription
                .$errors" :key="index" class="text-theme-6 mt-2">
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>服務電話</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{ 'border-theme-6': validate.ServicePhone.$error }">
                  <FontAwesome icon="phone" class="w-6" />
                </div>
                <input v-model.trim="validate.ServicePhone.$model" type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{ 'border-theme-6': validate.ServicePhone.$error }" placeholder="請輸入電話號碼"
                  name="ServicePhone" />
              </div>
              <template v-if="validate.ServicePhone.$error">
                <div v-for="(error, index) in validate.ServicePhone.$errors" :key="index" class="text-theme-6 mt-2">
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>公司傳真</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{ 'border-theme-6': validate.CompanyFax.$error }">
                  <FontAwesome icon="fax" class="w-6" />
                </div>
                <input v-model.trim="validate.CompanyFax.$model" type="text"
                  :class="{ 'border-theme-6': validate.CompanyFax.$error }" class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入電話號碼" name="CompanyFax" />
              </div>
              <template v-if="validate.CompanyFax.$error">
                <div v-for="(error, index) in validate.CompanyFax.$errors" :key="index" class="text-theme-6 mt-2">
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>公司地址</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{ 'border-theme-6': validate.CompanyAddress.$error }">
                  <FontAwesome type="fas" icon="address-card" class="w-6" />
                </div>
                <input v-model.trim="validate.CompanyAddress.$model" type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{ 'border-theme-6': validate.CompanyAddress.$error }" placeholder="請輸入地址"
                  name="CompanyAddress" />
              </div>
              <template v-if="validate.CompanyAddress.$error">
                <div v-for="(error, index) in validate.CompanyAddress.$errors" :key="index" class="text-theme-6 mt-2">
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="mt-3">
              <label>服務信箱</label>
              <div class="relative mt-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                  :class="{ 'border-theme-6': validate.ServiceEmail.$error }">
                  <FontAwesome icon="at" class="w-6" />
                </div>
                <input v-model.trim="validate.ServiceEmail.$model" type="text"
                  class="input pl-12 w-full border col-span-4"
                  :class="{ 'border-theme-6': validate.ServiceEmail.$error }" placeholder="請輸入Email"
                  name="ServiceEmail" />
              </div>
              <template v-if="validate.ServiceEmail.$error">
                <div v-for="(error, index) in validate.ServiceEmail.$errors" :key="index" class="text-theme-6 mt-2">
                  {{ error.$message }}
                </div>
              </template>
            </div>
          </div>
          <!-- END: Form Layout -->
        </div>
        <div class="intro-y col-span-12 lg:col-span-6">
          <!-- BEGIN: Form Layout -->
          <div class="intro-y box p-5 h-full">
            <div class="mt-3">
              <label>第三方網站追蹤代碼</label>
              <textarea v-model.trim="validate.TrackingCode.$model" class="input w-full border mt-2 h-64"
                :class="{ 'border-theme-6': validate.TrackingCode.$error }" placeholder="請輸入追蹤代碼"></textarea>
              <template v-if="validate.TrackingCode.$error">
                <div v-for="(error, index) in validate.TrackingCode.$errors" :key="index" class="text-theme-6 mt-2">
                  {{ error.$message }}
                </div>
              </template>
            </div>
          </div>
          <!-- END: Form Layout -->
        </div>
        <div class="intro-y col-span-12 lg:col-span-12">
          <div class="text-right">
            <button type="submit" class="button w-24 bg-theme-1 text-white">
              更新
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, url } from '@vuelidate/validators'
import context from '@cloudfun/core'

export default defineComponent({
  setup() {
    const application = context.root
    const configuration = reactive({
      AdminTitle: '',
      PortalTitle: '',
      PortalMetaKeywords: '',
      PortalMetaDescription: '',
      ServicePhone: '',
      CompanyFax: '',
      CompanyAddress: '',
      ServiceEmail: '',
      TrackingCode: ''
    })

    const rules = {
      AdminTitle: { required },
      PortalTitle: { required },
      PortalMetaKeywords: { required },
      PortalMetaDescription: { required },
      ServicePhone: {},
      CompanyFax: {},
      CompanyAddress: {},
      ServiceEmail: { email },
      TrackingCode: {}
    }

    const validate = useVuelidate(rules, toRefs(configuration))

    const save = () => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        context.send('warning', {
          subject: '資料有誤',
          content: '資料驗證失敗，請依提示進行修正後再試一次'
        })
      } else if (application) {
        application.model.dispatch('configuration/update', configuration).then(
          () => { // success
            application.model.dispatch('configuration/read')
            context.send('info', {
              subject: '更新成功',
              content: '系統配置已更新'
            })
          },
          failure => {
            context.send('error', {
              subject: '更新失敗',
              content: failure.message
            })
          }
        )
      }
    }

    onMounted(() => {
      if (application) application.model.dispatch('configuration/read').then(value => Object.assign(configuration, value));
    })

    return {
      configuration,
      validate,
      save
    }
  }
})
</script>
